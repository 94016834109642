import React from 'react'
import DrawQuestionMark from '../DrawQuestionMark/DrawQuestionMark'
import './WhenWhereEclipse.css'
import DrawGlasses from '../DrawGlasses/DrawGlasses'

const WhenWhereEclipse = () => {
  return (
    <section className='whenWhereEclipseWrapper'>
    <div className='whenWhereEclipse'>
        <div className='whenWhereWatch WhereWhereBorderBG'>
            <div className='whenWhereWatchText'>
            <h2>When & Where to Watch</h2>
            <p>Catch the 2024 Solar Eclipse live! Explore our detailed timeline and find the best viewing locations worldwide. Click below for more information.</p>
            <a target="_blank" href="https://www.in.gov/dhs/solar-eclipse-2024/files/TSE24-Totality-Durations-Map.pdf ">More Info</a>
            </div>
        </div>
        <div className='safeObserve WhereWhereBorderBG'>
        <div className='whenWhereWatchText'>
            <h2>How to Safely Observe</h2>
            <p>Ensure a secure viewing experience with solar eclipse glasses. Click below for essential safety tips and where to get your protective eyewear.</p>
            <a target="_blank" href="https://news.iu.edu/live/news/31934-ask-the-expert-protecting-your-eyes-during-a-solar">More Info</a>
            </div>
        </div>

        <div className='questionMarkContainer'><DrawQuestionMark /></div>
        <div className='glassesContainer'><DrawGlasses /></div>
    </div>
    </section>
  )
}

export default WhenWhereEclipse