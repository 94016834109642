import React, { useEffect, useRef } from 'react';

const EclipseStarGraphicAnimation = ({ points }) => {
  const canvasRef = useRef(null);
  const animationStartTimeRef = useRef(null);
  const lineAnimationsRef = useRef([]);
  const starsAnimationDuration = 1000; // 1 second for star animation
  const starsAnimationCompleteRef = useRef(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Function to draw a star
    const drawStar = (x, y, progress) => {
      const radius = 2 * progress;
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, Math.PI * 2);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.closePath();
    };

    // Function to draw a line between two points
    const drawLine = (x1, y1, x2, y2, progress) => {
      const dx = x2 - x1;
      const dy = y2 - y1;
      const x = x1 + dx * progress;
      const y = y1 + dy * progress;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x, y);
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.closePath();
    };

    // Function to animate a specific line
    const animateLine = (lineIndex) => {
      const currentTime = Date.now();
      const progress = Math.min((currentTime - animationStartTimeRef.current) / 400, 1); // Animation duration: 4 seconds

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw stars with animation if stars animation is not complete
      if (!starsAnimationCompleteRef.current) {
        points.forEach(point => drawStar(point.x, point.y, progress));
      } else {
        // Draw static stars after stars animation is complete
        points.forEach(point => drawStar(point.x, point.y, 1));
      }

      // Draw lines up to the current line index with animation
      for (let i = 0; i < lineIndex; i++) {
        const line = points[i];
        drawLine(line.x, line.y, points[i + 1].x, points[i + 1].y, 1);
      }

      // Draw the current line with animation
      const currentLine = points[lineIndex];
      drawLine(currentLine.x, currentLine.y, points[lineIndex + 1].x, points[lineIndex + 1].y, progress);

      if (progress < 1) {
        // Continue the animation if not completed
        requestAnimationFrame(() => animateLine(lineIndex));
      } else if (lineIndex < points.length - 2) {
        // Move to the next line after completing the current one
        animationStartTimeRef.current = Date.now();
        animateLine(lineIndex + 1);
      }
    };

    const animateStars = () => {
      const currentTime = Date.now();
      const progress = Math.min((currentTime - animationStartTimeRef.current) / starsAnimationDuration, 1);

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw stars with animation
      points.forEach(point => drawStar(point.x, point.y, progress));

      if (progress < 1) {
        // Continue the animation if not completed
        requestAnimationFrame(animateStars);
      } else {
        // Stars animation complete
        starsAnimationCompleteRef.current = true;

        // Start drawing lines once stars animation is complete
        animationStartTimeRef.current = Date.now();
        animateLine(0);
      }
    };

    const handleScroll = () => {
      const rect = canvas.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        (rect.bottom - 180) <= window.innerHeight

      if (isVisible && animationStartTimeRef.current === null && !starsAnimationCompleteRef.current) {
        // Start the stars animation when the entire component is in view and animation hasn't been started
        animationStartTimeRef.current = Date.now();
        animateStars();
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [points]);

  return (
    <canvas
      ref={canvasRef}
      width="500"
      height="500"

    
    ></canvas>
  );
};

export default EclipseStarGraphicAnimation;
