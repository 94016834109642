import React, { useState } from 'react';
import './CelestialSect.css';

const CelestialSect = () => {
  const topics = [
    {
      title: 'The Solar Eclipse',
      content: 'Embark on a geological journey with the 2024 Solar Eclipse, a celestial spectacle where Earth\'s energy takes center stage. As the moon, sun, and planet align, witness the intricate dance of geological forces, casting a captivating shadow that unveils the Earth\'s energetic landscape. Join this cosmic performance, where energy and geology converge in a mesmerizing display of nature\'s wonders.',
    },
    {
      title: 'IWBN Stations',
      content: 'The Indiana Water Balance Network is excited to put some numbers behind the changes that everyone will experience during the Total Eclipse on April 8th. We are setting up experiments that utilize this rare occurrence to help us gather data. Our stations will ramp up collection frequency of sensor data on temperature, wind, solar radiation, light, water levels and pressure changes.  We are looking forward to seeing the impact this extraterrestrial event plays on the aspects of a water balance in Indiana. ',
    },
    {
      title: 'Earth Tides',
      content: 'Deformation of the solid earth occurs as it rotates within the gravitational fields of the Sun and Moon. The earth deforms causing stress upon the pore space within our aquifers.  Tidal effects may also be witnessed in our groundwater.  We are going to be setting up experiments surrounding the eclipse to determine if the earth tides can be detected in Indiana aquifers.  This research will allow us to better understand aquifers, or underground drinking water reservoirs, properties and for most Hoosiers.',
    },
    {
      title: 'How can I contribute?',
      content: 'Stay tuned for more information on the upcoming experiment conducted by the IGWS Water Balance Network, featuring active participation from members of the public!',
    },
  ];

  const [selectedTopic, setSelectedTopic] = useState(topics[0]);

  const handleTopicChange = (newTopic) => {
    setSelectedTopic(newTopic);
  };

  return (
    <section className='celestial__section__wrapper'>
      <div className='celestial__section__quote'>
        <blockquote>
          <p>"If, during the progress of a total [solar] eclipse, the gradually diminishing crescent of the sun is watched, nothing remarkable is seen until very near the moment of its total disappearance. But, as the last ray of sunlight vanishes, a scene of unexampled beauty, grandeur, and impressiveness breaks upon the view." </p>

          <p>- Simon Newcomb <cite>Popular Astronomy</cite> 1890</p>
        </blockquote>
      </div>
      <div className='celestial__section'>
        <div className='celestial__section__graphic'>
          <h2>Related Topics</h2>
          {topics.map((topic) => (
            <button
              key={topic.title}
              className={`celestial__left__section__link ${selectedTopic.title === topic.title ? 'active' : ''}`}
              onClick={() => handleTopicChange(topic)}
            >
              {topic.title}
            </button>
          ))}
        </div>
        <div className='celestial__section__text'>
          <h2>{`Celestial Marvel: ${selectedTopic.title}`}</h2>
          <p>{selectedTopic.content}</p>
        </div>
      </div>
    </section>
  );
};

export default CelestialSect;
