import React from 'react'
import StaffCard from '../StaffCard/StaffCard'
import './StaffCategoryContainer.css'

//takes a list and returns a bundle of cards
const StaffCategoryContainer = (props) => {

  return (
    <div className='staff__category__container'>
        <h2>{props.category}</h2>
        <div className='staff__category__card__wrapper'>
        {props.content.map((person, index)=>{
            return(<StaffCard fName={person.fName} lName={person.lName} link={person.username} image={person.picture} role={person.title} key={index} delay={index}/>)
        })}
        </div>
    </div>
  )
}

export default StaffCategoryContainer