import React, { useState } from 'react';
import './EclipseNav.css'
import { Link } from 'react-router-dom'

const EclipseNav = () => {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };
  

    const eclipseLinks = [
        {label: "Eclipse Home", link: "/eclipse", external: false, active: true}, 
        {label: "Gallery & Data", link: "/eclipse/data", external: false, active: false},
        {label: "Data Submission", link: "/eclipse/whenWhereWatch", external: false, active: false},
        {label: "Current Research", link: "/safetyInfo", external: true, active: false}]
  return (
    <nav className='eclipse-nav-wrapper'>
    <div className={`hamburger-icon-eclipse ${isMobileMenuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
      <span className='line one'></span>
      <span className='line two'></span>
      <span className='line three'></span>
    </div>
    <ul className={`eclipse-nav ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      
        {eclipseLinks.map((item, index)=>{
            if(item.active){
            if(item.external) {
                return(<li key={index}><a href={item.link}>{item.label}</a></li>)
            } else {
                return(<li  key={index}><Link to={item.link}>{item.label}</Link></li>)
            }
            }
            else {
                return(<li  key={index}>{item.label}<div className='coming-soon-tab-eclipse'>Coming Soon</div></li>)
            }
        })}
    </ul>
    </nav>
  )
}

export default EclipseNav