import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './NewsNavbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud} from '@fortawesome/free-solid-svg-icons'
import { primaryAPILink } from '../../../../config/routes';

const NewsNavbar = () => {
    const [featuredLink, setFeaturedLink] = useState("");

    useEffect(() => {
        fetch(`${primaryAPILink}/api/news/getFeaturedPage`)
            .then(res => res.json())
            .then((result) => {
              if(result.length === 0) {
                return
              } else {
                const ourLink = result[0].newsStoryLink;
                console.log(ourLink)
                fetch(`${primaryAPILink}/api/news/story/${ourLink}`)
                .then(res => res.json())
                .then((result) => {
                    console.log(result)
                    if(result.length !==0){
                        setFeaturedLink(result[0].newsStoryLink);
                    }
                })
            
              }
            })}, []
    )
  return (
    <nav className='news__navbar'>
    <ul>
        <div className='left__news__navbar__items'>
            <li><Link to="/News">News</Link></li>
            <li><Link to={`/News/story/${featuredLink}`} >Featured Story</Link></li>
            <li><Link to="/News/all-stories">All Stories</Link></li>
            <li><Link to="/newsletters">Past Newsletters</Link></li>
        </div>
        {/* <span className='weathertab__news'><FontAwesomeIcon icon={faCloud} />77°F</span> */}
    </ul>
    </nav>
  )
}

export default NewsNavbar