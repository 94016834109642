import React, { useState, useEffect } from 'react';
import './Eclipse.css'
import EclipseHeader from './EclipseSections/EclipseHeader/EclipseHeader'
import EclipseNav from './EclipseNav/EclipseNav'
import CelestialSect from './EclipseSections/CelestialSect/CelestialSect';
import DiscoverEclipse from './EclipseSections/DiscoverEclipse/DiscoverEclipse';
import EclipseLine from './EclipseSections/EclipseLine/EclipseLine';
import NasaViewEclipse from './EclipseSections/NasaViewEclipse/NasaViewEclipse';
import EclipseStarGraphicAnimation from './EclipseSections/EclipseStarGraphicAnimation/EclipseStarGraphicAnimation';
import DrawQuestionMark from './EclipseSections/DrawQuestionMark/DrawQuestionMark';
import WhenWhereEclipse from './EclipseSections/WhenWhereEclipse/WhenWhereEclipse';
import EclipseImageGrid from './EclipseSections/EclipseImageGrid/EclipseImageGrid';

const Eclipse = () => {

    document.title = "IGWS | 2024 Solar Eclipse"

    const [screenWidth, setScreenWidth] = useState(window.innerWidth - 30);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    function calcTotalStars() {
        const reasonableMaxStars = 1000; // Choose a reasonable maximum number of stars
        let starCalc = Math.floor(screenWidth * screenHeight * 0.0003);
    
        starCalc = Math.max(0, starCalc); // Ensure starCalc is non-negative
    
        if (starCalc > reasonableMaxStars) {
            return reasonableMaxStars;
        } else {
            return starCalc;
        }
    }


    const maxStars = 500;
    const totalStars = calcTotalStars();

    function getRandomNumber(max) {
        return Math.floor(Math.random() * max) + 1;
    }

    function getRandomPercentage() {
        return Math.random() * 100;
    }


    const handleResize = () => {
        const newWidth = window.innerWidth - 300;
        const newHeight = window.innerHeight;

        // Check if the width has changed
        if (newWidth !== screenWidth) {
            setScreenWidth(newWidth);
            setScreenHeight(newHeight);
        }
    };

    useEffect(() => {


        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);


    function generateStar() {

        let x = getRandomPercentage();
        let y = getRandomPercentage();
        let size = getRandomNumber(4) + 1;

        let duration = getRandomNumber(5) + 3;
        let isBlurred = Math.random() < 0.15; // 15% chance of being blurred
        let isColoredStar = Math.random() < 0.4;




        const snowflakeStyle = {
            position: 'absolute',
            left: `${x}%`,
            top: `${y}%`,
            width: `${size}px`,
            height: `${size}px`,
            filter: isBlurred ? 'blur(2px)' : 'none',
            backgroundColor: isColoredStar ? 'rgba(20, 79, 143, 0.676)' : 'white',
            borderRadius: '50%',
            opacity: 0,
            animation: `starsGlow ${duration}s ease 0s infinite`
        };

        return <div style={snowflakeStyle}></div>;

    }


    return (
        <div id="eclipsePageWrapper">
            <div className='starsWrapper'>
                {[...Array(totalStars)].map((_, index) => (
                    <React.Fragment key={index}>{generateStar()}</React.Fragment>
                ))}</div>
            <EclipseNav />
            <EclipseHeader />
            
            <EclipseLine text="The 2024 Solar Eclipse"></EclipseLine>

            <CelestialSect />
            <div className='eclipseHorizontalLine'></div>
            <DiscoverEclipse />
            <WhenWhereEclipse />
            <EclipseLine text="Featured Media"></EclipseLine>


            <NasaViewEclipse />
            <EclipseImageGrid imageList={[["https://data.igws.indiana.edu/filestore/0/9/3/5/5_db9128eb105d2bf/55390scr_99b41d20e6c24e0.jpg?v=1690913076", "IWBN Station"],
            ["https://data.igws.indiana.edu/filestore/2/7/3/5/5_c91d1538e53c968/55372web_d40d02f3d0b1c54.jpg?v=1690913051", "IWBN Station"], 
            ["https://data.igws.indiana.edu/filestore/7/9/3/5/5_002712f10aa7798/55397scr_088d0ec099d2913.jpg?v=1690913084", "IWBN Station"]]}/>


        </div>
    )
}

export default Eclipse