import React from 'react'
import './UserPermissionsTable.css'
import { useEffect, useState } from 'react'
import {primaryAPILink} from '../../../../config/routes.js'
import UserPermissionTableRow from './UserPermissionTableRow/UserPermissionTableRow'

const UserPermissionsTable = () => {
  const [tableInfo, setTableInfo] = useState([])
    useEffect(() => {
        fetch(`${primaryAPILink}/api/permissions/staffPermissions`, {headers: {jwtKey: localStorage.getItem("loginKey")}})
              .then(res => res.json())
              .then(
                (result) => {
                  if(result.length > 0) {
                    console.log(result)
                    setTableInfo(result);
                  }
                }
              )
          
      }, [])
  return (
    <div className='user__permissions__wrapper'>
      <table className='user__permissions__admin'>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Title</th>
          <th>Category</th>
          <th>Picture</th>
          <th>Active</th>
          <th>Admin</th>
          <th>Edit Alerts</th>
          <th>Edit News</th>
          <th>Edit Jobs</th>
          <th>Edit CMS</th>
        </tr>
      {tableInfo.map((user, index)=>{
        return(<UserPermissionTableRow user={user} key={index}/>)
      })}
      </table>
    </div>
  )
}

export default UserPermissionsTable