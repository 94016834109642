import React from 'react'
import './StaffProfileEditor.css'
import { useEffect, useState } from 'react'
import {primaryAPILink} from '../../../../config/routes.js'
import StaffProfileBanner from '../../../Staff/StaffProfile/StaffProfileBanner/StaffProfileBanner.jsx'
import StaffProfileName from '../../../Staff/StaffProfile/StaffProfileName/StaffProfileName.jsx'
import StaffProfilePicture from '../../../Staff/StaffProfile/StaffProfilePicture/StaffProfilePicture.jsx'
import StaffProfileTitle from '../../../Staff/StaffProfile/StaffProfileTitle/StaffProfileTitle.jsx'

const StaffProfileEditor = () => {
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [title, setTitle] = useState("")
    const [picture, setPicture] = useState("")
    const [bannerType, setBannerType] = useState("")
    const [bannerEditOpen, setBannerEditOpen] = useState(false)
    const [email, setEmail] = useState(["Loading..."]);
    const [phone, setPhone] = useState(["Loading..."]);
    const [description, setDescription] = useState(["Loading..."]);
    const [specialtiesList, setSpecialtiesList] = useState([]);

    useEffect(() => {
        fetch(`${primaryAPILink}/api/staff/specificPersonJWT/${localStorage.getItem("loginKey")}`)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.length > 0) {
              console.log(result)
              setFName(result[0].fName)
              setLName(result[0].lName)
              setTitle(result[0].title)
              setPicture(result[0].picture)
              setBannerType(result[0].bannerType)
              setEmail(result[0].email);
              setPhone(result[0].phone);
              setDescription(result[0].description);
              if(result[0].specialties !== "") {
                setSpecialtiesList(result[0].specialties.split(",")) 
              }
            }
          }
        )
    }, [])

    function updateBanner(value) {
        setBannerType(value)
        setBannerEditOpen(false)
    }

    function updateUserInfo(){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey")},
        body: JSON.stringify({
        bannerType: bannerType,
        description: description,
        specialties: specialtiesList.toString()})
    };
      fetch(`${primaryAPILink}/api/staff/updateProfile`, requestOptions)
    }


    const [drawButton, setDrawButton] = useState(<></>)
    useEffect(()=>{
        if(specialtiesList.length > 3) {
          setDrawButton(<></>)
        } else {
          setDrawButton(<li><input maxlength="30" onBlur={(event)=>{
            
            let newList = [...specialtiesList]
            newList.push(event.target.value)
            setSpecialtiesList(newList)
            
            }}></input>
         </li>)
        }
      }, [specialtiesList])

  return (
    <div id="staff__profile__content" className='admin__staff__profile__editor'>
        <StaffProfileBanner bannerType={`banner${bannerType}`} />
        <button className='staff__profile__editor__banner__button' onClick={()=>setBannerEditOpen(true)}>Change Banner</button>
        <div className={bannerEditOpen ? 'bannerOptionsStaffProfileEditor open' : 'bannerOptionsStaffProfileEditor'}>
            <button className='bannerButton banner0' onClick={()=>{updateBanner(0)}}></button>
            <button className='bannerButton banner1' onClick={()=>{updateBanner(1)}}></button>
            <button className='bannerButton banner2' onClick={()=>{updateBanner(2)}}></button>
            <button className='bannerButton banner3' onClick={()=>{updateBanner(3)}}></button>
            <button className='bannerButton banner4' onClick={()=>{updateBanner(4)}}></button>
            <button className='bannerButton banner5' onClick={()=>{updateBanner(5)}}></button>
            <button className='bannerButton banner6' onClick={()=>{updateBanner(6)}}></button>
        </div>
        <div className='staff__profile__head'>
        <StaffProfilePicture pictureLink={picture} altText={fName +" "+ lName + ` profile picture`} />
            <div className='staff__profile__head__text'>
                <StaffProfileName fName={fName} lName={lName} /> 
                <StaffProfileTitle title={title}/>
            </div>
        </div>
            <div className='staff__profile__contact'>
            <div className='staff__profile__contact__item'>
              <h3>Email:</h3>
              <p>{email}</p>
            </div>
            <div className='staff__profile__contact__item'>
              <h3>Phone:</h3>
              <p>{phone}</p>
            </div>
          </div>
          <div className='staff__profile__bio'>
            <h3>Biography:</h3>
            <p 
            contentEditable="true" 
            onBlur={(event)=>{
              let ourText = event.target.innerText
              setDescription(ourText)
              console.log('test,')

          
          }
          }
            suppressContentEditableWarning={true}>{description}</p>

            <h3>Specialties:</h3>
            <ul class="staff__profile__specialties__container">
              {drawButton}
              {specialtiesList.map((specialty, index) => {
                return(<li key={index}>{specialty}<button onClick={()=>{  
                  let tempList = [...specialtiesList]
                  tempList.splice(index, 1)

                  setSpecialtiesList(tempList)}}>x</button></li>)
              })}
            </ul>
          </div>
          <button onClick={()=>updateUserInfo()}>update page</button>

        </div>
  )
}

export default StaffProfileEditor