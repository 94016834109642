import React from 'react'
import './CrimsonLinkButton.css'
import {primaryLink} from '../../config/routes.js'
import { useState } from 'react'
import { useEffect } from 'react'

const CrimsonLinkButton = (props) => {
  const [target, setTarget] = useState("_self")
  useEffect(() => {
    if(props.link.includes(":")) {
      setTarget("_blank")
    }

    if(props.link.includes(primaryLink)){
      setTarget("_self")
    }
  }, [])
  
  return (
    <a className="crimson__link__button" target={target} href={props.link}>{props.text}</a>
  )
}

export default CrimsonLinkButton