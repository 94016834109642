import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './AdminNavbar.css'
import { primaryAPILink } from '../../../config/routes'

const AdminNavbar = (props) => {
    const [home, setHome]= useState(false)
    const [staff, setStaff]= useState(false)
    const [cms, setCms]= useState(false)
    const [news, setNews]= useState(false)
    const [alert, setAlert]= useState(false)
    const [jobs, setJobs]= useState(false)
    const [perms, setPerms]= useState(false)
    const [hero, setHero]= useState(false)
    const [projects, setProjects] = useState(false)
    useEffect(() => {
        if(props.active === "home") {
            setHome(true);
        }
        if(props.active === "news") {
            setNews(true);
        }
        if(props.active === "alert") {
            setAlert(true);
        }
        if(props.active === "perms") {
            setPerms(true);
        }
        if(props.active === "jobs") {
            setJobs(true);
        }
        if(props.active === "cms") {
            setCms(true);
        }
        if(props.active === "staff") {
            setStaff(true);
        }
        if(props.active === "hero") {
            setHero(true);
        }
        if(props.active === "projects") {
            setProjects(true);
        }
      
    }, [])

    //get which permissions the user has
    const [editNews, setEditNews] = useState("none")
    const [editCMS, setEditCMS] = useState("none")
    const [editJobs, setEditJobs] = useState("none")
    const [editAlert, setEditAlert] = useState("none")
    const [editPermissions, setEditPermissions] = useState("none")

    useEffect(() => {
    fetch(`${primaryAPILink}/api/staff/specificPersonJWT/${localStorage.getItem("loginKey")}`)
        .then(res => res.json())
        .then((result) => {
          if(result.length === 0) {
            window.location.replace(`/login`)
          } else {
            console.log(result[0])
            if(result[0].editNews === 1) {
                setEditNews("block")
            }
            if(result[0].editCMS === 1) {
                setEditCMS("block")
            }
            if(result[0].editJobs === 1) {
                setEditJobs("block")
            }
            if(result[0].editAlert === 1) {
                setEditAlert("block")
            }
            if(result[0].isAdmin === 1) {
                setEditPermissions("block")
            }
          }
        })}, []
)
    



  return (
    <div className='admin__navbar'>
        <ul>
            <li className={home ? "active" : ""}><Link to="/admin">Home</Link></li>
            <li className={staff ? "active" : ""}><Link to="/admin/staff-editor">Staff Profile Editor</Link></li>
            <li className={cms ? "active" : ""} style={{display: editCMS}}><Link to="/admin/cms-edit">CMS</Link></li>
            <li className={news ? "active" : ""} style={{display: editNews}}><Link to="/admin/news-edit">News</Link></li>
            <li className={alert ? "active" : ""} style={{display: editAlert}}><Link to="/admin/public-alerts">Public Alerts</Link></li>
            <li className={hero ? "active" : ""} style={{display: editAlert}}><Link to="/admin/hero-editor">Hero Editor</Link></li>
            <li className={jobs ? "active" : ""} style={{display: editJobs}}><Link to="/admin/jobs-edit">Jobs</Link></li>
            <li className={projects ? "active" : ""} style={{display: editCMS}}><Link to="/admin/research-projects">Research Projects</Link></li>
            <li className={perms ? "active" : ""} style={{display: editPermissions}}><Link to="/admin/user-permissions">User Permissions</Link></li>
        </ul>

    </div>
  )
}

export default AdminNavbar