import React from 'react'
import {useState, useEffect} from 'react'
import {primaryAPILink} from '../../../config/routes.js'
import {Link, useParams, useNavigate} from 'react-router-dom';
import './StaffProfile.css'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import StaffProfileName from './StaffProfileName/StaffProfileName.jsx';
import StaffProfilePicture from './StaffProfilePicture/StaffProfilePicture.jsx';
import StaffProfileBanner from './StaffProfileBanner/StaffProfileBanner.jsx';
import StaffProfileTitle from './StaffProfileTitle/StaffProfileTitle.jsx';
import StaffProfileSpecialties from './StaffProfileSpecialties/StaffProfileSpecialties.jsx';

const StaffProfile = () => {
    //get link from url
    const params = useParams();
    let ourStaffLink = params.staffLink;
    const navigate = useNavigate();

    //profile info
    const [fName, setFName] = useState(["Loading..."]);
    const [lName, setLName] = useState(["Loading..."]);
    const [description, setDescription] = useState(["Loading..."]);
    const [picture, setPicture] = useState(["Loading..."]);
    const [title, setTitle] = useState(["Loading..."]);
    const [email, setEmail] = useState(["Loading..."]);
    const [phone, setPhone] = useState(["Loading..."]);
    const [specialties, setSpecialties] = useState(["Loading..."]);
    const [bannerType, setBannerType] = useState(0)
    document.title = `IGWS | ${fName} ${lName}`;

    useEffect(() => {
      fetch(`${primaryAPILink}/api/staff/specificperson/${ourStaffLink}`)
          .then(res => res.json())
          .then((result) => {
            if(result.length === 0) {
              //error case
              navigate('/staff')
            } else {
              setFName(result[0].fName);
              setLName(result[0].lName);
              setDescription(result[0].description);
              setPicture(result[0].picture);
              setTitle(result[0].title);
              setEmail(result[0].email);
              if(result[0].phone !== "") {
                setPhone(<div className='staff__profile__contact__item'>
                <h3>Phone:</h3>
                <p>{result[0].phone}</p>
              </div>);
              } else {
                setPhone(<></>)
              }
              if(result[0].specialties.split(',').length > 0 && result[0].specialties.split(',')[0] !== 
            ""){
                setSpecialties(<StaffProfileSpecialties specialties={result[0].specialties.split(',')}/>);
              } else {
                setSpecialties(<></>)
              }
              setBannerType(result[0].bannerType);
            }
          })}, [navigate, ourStaffLink]);
    


  return (
    <div id="staff__profile__wrapper">
      <CrimsonBlockHeader text="Staff Listing" />
      <nav>
        <ul>
          <li><Link to="/staff" >All Staff</Link></li>
        </ul>
      </nav>
      <div id="staff__profile__content">
        <StaffProfileBanner bannerType={`banner${bannerType}`} />
        <div className='staff__profile__head'>
          <StaffProfilePicture pictureLink={picture} altText={fName +" "+ lName + ` profile picture`} />
          <div className='staff__profile__head__text'>
            <StaffProfileName fName={fName} lName={lName} /> 
            <StaffProfileTitle title={title}/>
          </div>
        </div>
        <div className='staff__profile__contact'>
            <div className='staff__profile__contact__item'>
              <h3>Email:</h3>
              <p>{email}</p>
            </div>
              {phone}
          </div>
          {specialties}
          <div className='staff__profile__bio'>
            <h3>Biography:</h3>
            <p>{description}</p>
          </div>
         
      </div>

      
    </div>
  )
}

export default StaffProfile