import React from 'react'
import { useState, useEffect } from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import AllNewsStoriesStory from './AllNewsStoriesStory/AllNewsStoriesStory';
import './AllNewsStories.css'
import { convertToDate } from '../../../assets/Functions/HelpfulFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import NewsNavbar from '../NewsComponents/NewsNavbar/NewsNavbar';
import { primaryAPILink } from '../../../config/routes';


const AllNewsStories = () => {

  const [ourStories, setOurStories] = useState([])
  const [ourSearchStories, setOurSearchStories] = useState([])
  const [failedToLoad, setFailedToLoad] = useState(<h2 className='no__stories'>Fetching Stories...</h2>);

  useEffect(() => {
    fetch(`${primaryAPILink}/api/news/`)
      .then(res => res.json())
      .then((result) => {
        if (result.length === 0) {
          renderNoStories(true);
        } else {
          setOurStories(result)
          setOurSearchStories(result)
          setFailedToLoad(<></>)
        }
      })
  }, []
  )

  function searchStories(inputWord) {
    console.log(inputWord);
    let tempList = [];
    ourStories.forEach(item => {
      if (
        item.newsTitle.toLowerCase().includes(inputWord.toLowerCase()) ||
        item.newsCategory.toLowerCase().includes(inputWord.toLowerCase()) ||
        item.newsContent.toLowerCase().includes(inputWord.toLowerCase()) ||
        item.newsAuthor.toLowerCase().includes(inputWord.toLowerCase()) ||
        convertToDate(item.newsDatePublished).toLowerCase().includes(inputWord.toLowerCase())) {
        tempList.push(item);
      }
      setOurSearchStories(tempList);
      if (tempList.length === 0) {
        renderNoStories(true);
      } else {
        renderNoStories(false);
      }
    })
  }

  function renderNoStories(trueFalse) {
    if (trueFalse) {
      setFailedToLoad(<><h2 className='no__stories'>No stories matching that search</h2>
        <img className='no__stories' src="" alt="No news stories graphic of a sad face" /></>)
    } else {
      setFailedToLoad(<></>)
    }
  }

  const [desc, setDesc] = useState(true)
  function reverseList() {
    let tempList = [...ourSearchStories];
    tempList.reverse();
    setOurSearchStories(tempList)
    setDesc(!desc)
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // You can adjust this based on your preference
  const totalItems = ourSearchStories.length;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ourSearchStories.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className='all__news__stories__wrapper'>
      <CrimsonBlockHeader text="All Stories" />
      <NewsNavbar />
      <div className='all__news__stories__stories'>
        <div className='all__news__search__section'>
          <input type="text" placeholder='Search for stories...' onInput={(event) => searchStories(event.target.value)}></input>
          <button onClick={() => reverseList()} className={desc ? "newest-to-oldest reverse__button__news" : "oldest-to-newest reverse__button__news"}>
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        </div>
        {currentItems.map((item, index) => {
          return <AllNewsStoriesStory key={index} delay={index} title={item.newsTitle} newsImage={item.newsImage} snippet={item.newsSnippet} link={item.newsStoryLink} tag={item.newsCategory} date={convertToDate(item.newsDatePublished)} />
        })}
        {failedToLoad}
        <div className='pagination'>
          {pageNumbers.map((number) => (
            <button key={number} onClick={() => setCurrentPage(number)}>
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AllNewsStories