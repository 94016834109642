import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import AdminCMSHead from './AdminCMSHead/AdminCMSHead'

const AdminCMS = () => {
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="cms" />
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="CMS Editor" />
            <AdminContentExplain text={["CMS editor is used to update/ create content on the website", "Parent Category specifies what category the page will appear under", "Use 'other' to create custom links; However, they will not appear under the 5 main categories on the nav bar"]}></AdminContentExplain>
            <AdminCMSHead />
        </div>
    </div>
  )
}

export default AdminCMS