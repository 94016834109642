import React from 'react'
import { Link } from 'react-router-dom';
import './SwiperSlideHero.css'

const SwiperSlideHero = (props) => {
  return (
    <div className='swiper__slide__hero__wrapper'>
        <div className='swiper__slide__hero'>
            <span><Link to={props.link}>{props.category}</Link></span>
            <h2>{props.heading}</h2>
            <p>{props.paragraph}</p>
            <Link to={props.link} className='swiper__slide__hero__button'>Read More</Link>
        </div>
    </div>
  )
}

export default SwiperSlideHero