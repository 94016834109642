import React from 'react'
import './IndianaGeologyQuickGuideNew.css'
import RightArrow from '../../assets/icons/RightArrow';
import './IndianaGeologyQuickGuideNew.css'
import { Link } from 'react-router-dom';

const IndianaGeologyQuickGuideNew = () => {
  return (
    <div id="indianaGeologyQuickGuideCardContainer">
        <h2>General Guide to Indiana Geology</h2>
        <p className='indianaGeologyQuickGuideCardContainerInfo'>Use the menu below to discover more about Indiana's natural resources and hazards.</p>
        <div className="indianaGeologyQuickGuideCardGrid mainGeologyCardContainer">
            <Link to="/research/hazards" className={`indianaGeologyQuickGuideCard maincard`}>
                <div className='indianaGeologyQuickGuideCardHeader hazard'>
                    <h3>Hazards</h3>
                    <div className='arrowSVG'><RightArrow /></div>
                </div>
                <p>Learn more about the geologic hazards that affect the Hoosier state.</p>
            </Link>

            <Link to="/research/water" className={`indianaGeologyQuickGuideCard maincard`}>
                <div className='indianaGeologyQuickGuideCardHeader water'>
                    <h3>Water</h3>
                    <div className='arrowSVG'><RightArrow /></div>
                </div>
                <p>Learn more about surface water, groundwater, and aquifer resources in Indiana.</p>
            </Link>

            <Link to="/research/energy" className={`indianaGeologyQuickGuideCard maincard`}>
                <div className='indianaGeologyQuickGuideCardHeader energy'>
                    <h3>Energy</h3>
                    <div className='arrowSVG'><RightArrow /></div>
                </div>
                <p>Learn more about Indiana’s oil, gas, and coal resources and low-carbon energy technologies.</p>
            </Link>

            <Link to="/research/minerals" className={`indianaGeologyQuickGuideCard maincard`}>
                <div className='indianaGeologyQuickGuideCardHeader geology'>
                    <h3>Minerals</h3>
                    <div className='arrowSVG'><RightArrow /></div>
                </div>
                <p>Learn more about the state’s industrial minerals, critical minerals, and rare earth elements.</p>
            </Link>
        </div>
    </div>
  )
}

export default IndianaGeologyQuickGuideNew
