import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import HomeHero from '../HomeHero/HomeHero';
import "./HomeSwiper.css"
import { Autoplay, Pagination } from "swiper";
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperSlideHero from './SwiperSlideHero/SwiperSlideHero';
import { useEffect } from 'react';
import { useState } from 'react';
import { primaryAPILink } from '../../../config/routes.js'



const HomeSwiper = () => {

  const [swiperList, setSwiperList] = useState([])

  useEffect(() => {
    fetch(`${primaryAPILink}/api/eventbanner/homehero`)
      .then(res => res.json())
      .then((result) => {
        if (result.length > 0) {
          setSwiperList(result)
        }
      })
  }, []
  )

  return (
    <Swiper
      className='home__swiper'
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 10000,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
        type: 'bullets'
      }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      modules={[Autoplay, Pagination]}
    >
      <SwiperSlide>
        <HomeHero />
      </SwiperSlide>
      {swiperList.map((item, index)=>{
        return(
        <SwiperSlide className='extra__slides' style={{ backgroundImage: `url('${item.image}')` }} key={index}>
        <SwiperSlideHero category={item.category} heading={item.heading} paragraph={item.paragraph} link={item.link} />
      </SwiperSlide>)
      })}
    </Swiper>
  )
}

export default HomeSwiper