import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import './AdminStaffProfileEditor.css'
import StaffProfileEditor from './StaffProfileEditor/StaffProfileEditor'

const AdminStaffProfileEditor = () => {
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="staff"/>
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="Staff Profile Editor" />
            <StaffProfileEditor />
        </div>
    </div>
  )
}

export default AdminStaffProfileEditor