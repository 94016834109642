import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Facebook from '../../../assets/icons/Facebook'
import Twitter from '../../../assets/icons/Twitter'
import Instagram from '../../../assets/icons/Instagram'
import './IUFooter.css'
import LinkedIn from '../../../assets/icons/LinkedIn'
import { primaryAPILink } from '../../../config/routes'

const IUFooter = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
        fetch(`${primaryAPILink}/api/staff/specificPersonJWT/${localStorage.getItem("loginKey")}`)
            .then(res => res.json())
            .then((result) => {
                if (result.length > 0) {
                    setLoggedIn(true)
                }
            })
    }, []
    )

    return (
        <footer id="footer" role="contentinfo" itemScope="itemscope" itemType="https://schema.org/CollegeOrUniversity">
            <div className="row pad">
                <p className="signature">
                    <a href="https://www.iu.edu" className="signature-link signature-img" aria-label='Indiana Univeristy Website'><img src="//assets.iu.edu/brand/3.3.x/iu-sig-formal.svg" alt="Indiana University" /></a>
                </p>

                <p className="copyright">
                    <span className="line-break">
                        <a href="https://accessibility.iu.edu/assistance/" id="accessibility-link" title="Having trouble accessing this web page content? Please visit this page for assistance." aria-label='Indiana Univeristy Accessibility'>Accessibility</a> | <a href="/privacy/" id="privacy-policy-link">Privacy Notice</a>
                    </span>
                    <span className="hide-on-mobile"> | </span>
                    <a href="https://www.iu.edu/copyright/index.html">Copyright</a> &#169; 2022 <span className="line-break-small">The Trustees of <a href="https://www.iu.edu/" itemProp="url">
                        <span itemProp="name">Indiana University</span></a>
                    </span>
                    {loggedIn ?
                        <a href="/admin" className='staff__login__footer'>Admin Panel</a>

                        : <a href="/login" className='staff__login__footer'>Staff Login</a>}
                </p>
            </div>
            <div className='footer__socials'>
                <div className='footer__socials__wrapper'>
                    <a href="https://www.facebook.com/INGeoSurvey/" target="_blank" aria-label='IGWS Facebook'><Facebook alt="facebook logo" /></a>
                    <a href="https://www.instagram.com/ingeosurvey/" target="_blank" aria-label='IGWS Instagram'><Instagram alt="instagram logo" /></a>
                    <a href="https://twitter.com/ingeosurvey" target="_blank" aria-label='IGWS Twitter'><Twitter alt="twitter logo" /></a>
                    <a href="https://www.linkedin.com/company/indiana-geological-survey" target="_blank" aria-label='IGWS LinkedIn'><LinkedIn alt="linkedIn logo" /></a>
                </div>
            </div>
        </footer>
    )
}

export default IUFooter