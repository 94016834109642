import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import './assets/normalize.css';
import './assets/IUAssets/brand.css';
import './assets/IUAssets/search.css';
import { useState, useEffect } from "react";
import './App.css';
import Home from './pages/Home/Home';
import Nav from './components/navigation/Nav'
import IUFooter from './components/footer/IUFooter/IUFooter';
import IndianaMap from "./pages/Maps/IndianaMap/IndianaMap";
import Staff from "./pages/Staff/Staff";
import EditPageForm from "./components/EditPageForm/EditPageForm";
import { EditPageFormBase } from "./components/EditPageForm/EditPageFormBase";
import News from "./pages/News/News";
import NewsStory from "./pages/News/NewsStory/NewsStory";
import AllNewsStories from "./pages/News/AllNewsStories/AllNewsStories";
import NewsEdit from "./pages/Admin/NewsEdit/NewsEdit";
import AdminHome from "./pages/Admin/AdminHome/AdminHome";
import HigherOrderEvents from "./components/HigherOrderEvents/HigherOrderEvents";
import PublicAlerts from "./pages/Admin/PublicAlerts/PublicAlerts";
import UserPermissions from "./pages/Admin/UserPermissions/UserPermissions";
import StaffProfile from "./pages/Staff/StaffProfile/StaffProfile";
import Login from "./pages/Login/Login";
import Employment from "./pages/Employment/Employment";
import JobsEditor from "./pages/Admin/JobsEditor/JobsEditor";
import {primaryAPILink} from './config/routes'
import CMS from "./pages/CMS/CMS";
import AdminCMS from "./pages/Admin/AdminCMS/AdminCMS";
import AdminStaffProfileEditor from "./pages/Admin/AdminStaffProfileEditor/AdminStaffProfileEditor";
import AdminHeroEditor from "./pages/Admin/AdminHeroEditor/AdminHeroEditor";
import ImprovedIUBrandingBar from "./components/navigation/ImprovedIUBrandingBar/ImprovedIUBrandingBar";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Projects from "./pages/Projects/Projects";
import AdminProjects from "./pages/Admin/AdminProjects/AdminProjects";
import AdvancedSearch from "./pages/AdvancedSearch/AdvancedSearch";
import NotFound from "./pages/NotFound/NotFound";
import Loading from "./pages/Loading/Loading";
import EAssessment from "./pages/EAssessment/EAssessment";
import Eclipse from "./pages/Eclipse/Eclipse";
import ReactGA from "react-ga4";

function App() {

  const TRACKING_ID = 'G-7NS9P26LBH'
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: "/" });

  //fetch our routes
  const [routes, setRoutes] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${primaryAPILink}/api/cms/allPages`);
        if (response.ok) {
          const result = await response.json();
          const tempRoutes = result.map((item) => ({
            link: item.cmsParentGroup === "other" ? item.cmsLink : `${item.cmsParentGroup}/${item.cmsLink}`,
            cmsID: item.cmsID,
          }));
          setRoutes(tempRoutes);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDataLoaded(true);
      }
    }
    fetchData();



  },[]);


  


  return (
    <div className="App">
      <main>
      <HigherOrderEvents />
      <Router>
        <ImprovedIUBrandingBar />
        <Nav />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/search' element={<AdvancedSearch />} />
          <Route path='/staff' element={<Staff />} />
          <Route path='/form' element={<EditPageForm />} />
          <Route path='/form2' element={<EditPageFormBase/>} />
          <Route path='/news' element={<News />} />
          <Route path='/employment' element={<Employment />} />
          <Route path='/eAssessment' element={<EAssessment />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/admin/' element={<AdminHome />} />
          <Route path='/admin/cms-edit' element={<AdminCMS />} />
          <Route path='/admin/jobs-edit' element={<JobsEditor />} />
          <Route path='/admin/news-edit' element={<NewsEdit />} />
          <Route path='/admin/user-permissions' element={<UserPermissions />}/>
          <Route path='/admin/public-alerts' element={<PublicAlerts />} />
          <Route path='/admin/staff-editor' element={<AdminStaffProfileEditor />} />
          <Route path='/admin/hero-editor' element={<AdminHeroEditor />} />
          <Route path='/news/all-stories' element={<AllNewsStories />} />
          <Route path="/news/story/:newsLink" element={<NewsStory />} />
          <Route path="/admin/research-projects" element={<AdminProjects />} />
          <Route path="/staff/:staffLink" element={<StaffProfile />} />
          {routes.map((route, index)=>{
            return(<Route path={route.link} element={<CMS link={route.link} cmsID={route.cmsID} key={index}/>} key={index}/>)
          })}
          <Route path='/test/indiana-map'element={<IndianaMap />} />
          <Route path='/eclipse'element={<Eclipse />} />
          <Route path="*" element={dataLoaded ? <NotFound /> : <Loading />} />
        </Routes>
        <ScrollToTop />
        <IUFooter />
      </Router>
      </main>
      
    </div>
  );
}

export default App;
