import React from 'react'
import './DrawQuestionMark.css'
import EclipseStarGraphicAnimation from '../EclipseStarGraphicAnimation/EclipseStarGraphicAnimation'

const DrawQuestionMark = () => {
    const base = [
        { x: 100, y: 150 },
        { x: 140, y: 100 },
        { x: 250, y: 100 },
        { x: 300, y: 150 },
        { x: 300, y: 200 },
        { x: 250, y: 240 },
        { x: 200, y: 250 },
        { x: 200, y: 370 },
    ];

    const dot = [
        { x: 190, y: 400 },
        { x: 205, y: 405 },
        { x: 210, y: 420 },
        { x: 195, y: 425 },
        { x: 185, y: 415 },
        { x: 190, y: 400 },
    ];
    return (
        <div className='Draw__Question__Mark__Graphic'>
            <EclipseStarGraphicAnimation points={base} />
            <EclipseStarGraphicAnimation points={dot} />
        </div>
    )
}

export default DrawQuestionMark