import React, { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import IUBrandingBar from './IUBrandingBar/IUBrandingBar'
import './Nav.css'
import {primaryAPILink} from '../../config/routes.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import IGWS_Logo from '../../assets/Images/IGWS_Logo.svg';


const Nav = () => {


    const [navFetch, setNavFetch] = useState([]);
    const [aboutNavRoutes, setAboutNavRoutes] = useState([]);
    const [mapsNavRoutes, setMapsNavRoutes] = useState([]);
    const [collectionsNavRoutes, setCollectionsNavRoutes] = useState([]);
    const [outreachNavRoutes, setOutreachNavRoutes] = useState([]);
    const [researchNavRoutes, setResearchNavRoutes] = useState([]);
    const [servicesNavRoutes, setServicesNavRoutes] = useState([]);
    useEffect(() => {
        fetch(`${primaryAPILink}/api/cms/getNav`)
            .then(res => res.json())
            .then(
                (result) => {
                    let tempAbout = [];
                    let tempMaps = [];
                    let tempCollections = [];
                    let tempOutreach = [];
                    let tempGeo = [];
                    let tempServices = [];
                    result.forEach((item)=>{
                        if(item.cmsParentGroup === 'about') {
                            tempAbout.push(item)
                        }
                        if(item.cmsParentGroup === 'maps') {
                            tempMaps.push(item)
                        }
                        if(item.cmsParentGroup === 'collections') {
                            tempCollections.push(item)
                        }
                        if(item.cmsParentGroup === 'outreach') {
                            tempOutreach.push(item)
                        }
                        if(item.cmsParentGroup === 'research') {
                            tempGeo.push(item)
                        }
                        if(item.cmsParentGroup === 'services') {
                            tempServices.push(item)
                        }
                    })
                    setAboutNavRoutes(tempAbout);
                    setMapsNavRoutes(tempMaps);
                    setCollectionsNavRoutes(tempCollections);
                    setOutreachNavRoutes(tempOutreach);
                    setResearchNavRoutes(tempGeo);
                    setServicesNavRoutes(tempServices);
                },
                //error handling
                (error) => {
                    console.log(error)
                }
            )
    }, [])


    const [isNavExpanded, setIsNavExpanded] = useState(0);
    const [aboutExpanded, setAboutExpanded] = useState(true);
    const [mapsExpanded, setMapsExpanded] = useState(true);
    const [collectionsExpanded, setCollectionsExpanded] = useState(true);
    const [outreachExpanded, setOutreachExpanded] = useState(true);
    const [geoExpanded, setGeoExpanded] = useState(true);
    const [servicesExpanded, setServicesExpanded] = useState(true)

    return (
        <div className='igws__navigation__bar__wrapper'>
            <nav className='igws__navigation__bar'>
                <div className='igws__navigation__logo__container'>
                    <Link to='/'><img className='igws__navigation__logo' src={IGWS_Logo} alt="igws logo"></img></Link>
                    <div onClick={() => {
                        setIsNavExpanded(!isNavExpanded);
                        setAboutExpanded(true)
                        setCollectionsExpanded(true)
                        setGeoExpanded(true)
                        setMapsExpanded(true)
                        setOutreachExpanded(true)
                        setServicesExpanded(true)
                    }}
                        className={
                            isNavExpanded ? "navigation__toggle expanded" : "navigation__toggle"
                        }
                    ><span className='bar bar1'/><span className='bar bar2'/><span className='bar bar3'/></div>
                    
                </div>


                <div className={
                    isNavExpanded ? "navigation__links expanded" : "navigation__links"
                }>
                    
                    <div className='navigation__dropdown'>
                        
                        

                        <div className='navigation__dropdown__mobile'>
                            
                            <Link to="/about" className='navigation__dropdown__button' onClick={()=>setIsNavExpanded(0)} >About Us</Link>

                            <button onTouchStart={()=>setAboutExpanded(false)} onClick={()=>{setAboutExpanded(false)}} className='nav__expand__button'>&gt;</button>
                        </div>
                        <ul className={aboutExpanded ?'navigation__dropdown__content' : 'navigation__dropdown__content expanded'}>
                            <li className='mobileExpandButtonNavbar'><button onTouchStart={()=>setAboutExpanded(true)} onClick={()=>{setAboutExpanded(true)}}>&lt;</button> <a href="/about">About Us</a></li>
                            {aboutNavRoutes.map((route, index) => {
                                return (<Link to={`${route.cmsParentGroup}/${route.cmsLink}`}key={index} onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>{route.title}</li></Link>)
                            })}



                            <Link to='/staff' onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>Staff Listing</li></Link>
                            <Link to='/employment' onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>
                                Employment
                            </li></Link>
                            <Link to='/projects'  onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>
                               Research Projects
                            </li></Link>
                        </ul>
                    </div>


                    <div className='navigation__dropdown'>
                    <div className='navigation__dropdown__mobile'>
                    <Link to="/maps"  className='navigation__dropdown__button' onClick={()=>setIsNavExpanded(0)}>Maps & Pubs</Link>

                        <button onTouchStart={()=>setMapsExpanded(false)} onClick={()=>{setMapsExpanded(false)}} className='nav__expand__button'>&gt;</button>
                        </div>
                        <ul className={mapsExpanded ?'navigation__dropdown__content' : 'navigation__dropdown__content expanded'}>
                            <li className='mobileExpandButtonNavbar'><button onTouchStart={()=>setMapsExpanded(true)} onClick={()=>{setMapsExpanded(true)}}>&lt;</button> <a href="/maps">Maps</a></li>
                            {mapsNavRoutes.map((route, index) => {
                                return (<Link to={`${route.cmsParentGroup}/${route.cmsLink}`}  key={index} onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>{route.title}</li></Link>)
                            })}

                        </ul>
                    </div>



                    <div className='navigation__dropdown'>
                        <div className='navigation__dropdown__mobile'>
                        <Link to="/collections" className='navigation__dropdown__button' onClick={()=>setIsNavExpanded(0)} >Collections</Link>

                        <button onTouchStart={()=>setCollectionsExpanded(false)} onClick={()=>{setCollectionsExpanded(false)}}className='nav__expand__button'>&gt;</button>
                        </div>
                        <ul className={collectionsExpanded ?'navigation__dropdown__content' : 'navigation__dropdown__content expanded'}>
                        <li className='mobileExpandButtonNavbar'><button onTouchStart={()=>setCollectionsExpanded(true)} onClick={()=>{setCollectionsExpanded(true)}}>&lt;</button> <a href="/collections">Collections</a></li>
                        {collectionsNavRoutes.map((route, index) => {
                                return (<Link to={`${route.cmsParentGroup}/${route.cmsLink}`}key={index} onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>{route.title}</li></Link>)
                            })}
                        </ul>
                    </div>


                    <div className='navigation__dropdown'>
                        <div className='navigation__dropdown__mobile'>
                        <Link to="/outreach" className='navigation__dropdown__button' onClick={()=>setIsNavExpanded(0)}>Outreach</Link>

                        <button onTouchStart={()=>setOutreachExpanded(false)} onClick={()=>{setOutreachExpanded(false)}}className='nav__expand__button'>&gt;</button>
                        </div>
                        <ul className={outreachExpanded ?'navigation__dropdown__content' : 'navigation__dropdown__content expanded'}>
                            <li className='mobileExpandButtonNavbar'><button onTouchStart={()=>setOutreachExpanded(true)} onClick={()=>{setOutreachExpanded(true)}}>&lt;</button> <a href="/outreach">Outreach</a></li>
                            <Link to='/news' onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>
                                News
                            </li></Link>
                            {outreachNavRoutes.map((route, index) => {
                                return (<Link to={`${route.cmsParentGroup}/${route.cmsLink}`}key={index} onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>{route.title}</li></Link>)
                            })}
                        </ul>
                    </div>


                    <div className='navigation__dropdown'>
                        <div className='navigation__dropdown__mobile'>
                        <Link to="/research"className='navigation__dropdown__button' onClick={()=>setIsNavExpanded(0)}>Research</Link>

                        <button onTouchStart={()=>setGeoExpanded(false)} onClick={()=>{setGeoExpanded(false)}}className='nav__expand__button'>&gt;</button>

                        </div>
                        <ul className={geoExpanded ?'navigation__dropdown__content' : 'navigation__dropdown__content expanded'}>
    
                            
                            <li className='mobileExpandButtonNavbar'><button onTouchStart={()=>setGeoExpanded(true)} onClick={()=>{setGeoExpanded(true)}}>&lt;</button> <a href="/research">Research</a></li>

                           
                        {researchNavRoutes.map((route, index) => {
                                return (<Link to={`${route.cmsParentGroup}/${route.cmsLink}`}key={index} onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>{route.title}</li></Link>)
                            })}
                            
                        </ul>
                    </div>

                    <div className='navigation__dropdown'>
                        <div className='navigation__dropdown__mobile'>
                        <Link to="/services"className='navigation__dropdown__button' onClick={()=>setIsNavExpanded(0)}>Services</Link>

                        <button onTouchStart={()=>setServicesExpanded(false)} onClick={()=>{setServicesExpanded(false)}}className='nav__expand__button'>&gt;</button>

                        </div>
                        <ul className={servicesExpanded ?'navigation__dropdown__content' : 'navigation__dropdown__content expanded'}>
                            <li className='mobileExpandButtonNavbar'><button onTouchStart={()=>setServicesExpanded(true)} onClick={()=>{setServicesExpanded(true)}}>&lt;</button> <a href="/research">Services</a></li>
                        {servicesNavRoutes.map((route, index) => {
                                return (<Link to={`${route.cmsParentGroup}/${route.cmsLink}`}key={index} onClick={()=>setIsNavExpanded(0)}><li className='navigation__dropdown__header'>{route.title}</li></Link>)
                            })}
                        </ul>
                    </div>


                    <Link to="/search" className='advanced__search__link mobile' title="Advanced Search" onClick={()=>setIsNavExpanded(0)}>Search Site <FontAwesomeIcon icon={faSearch}/><FontAwesomeIcon icon={faEllipsisV} className="advanced__search__link__tiny__icon"/></Link>


                </div>
                
            </nav>
        </div>
    )
}

export default Nav