import React from 'react'
import EclipseStarGraphicAnimation from '../EclipseStarGraphicAnimation/EclipseStarGraphicAnimation';

const DrawGlasses =  () => {
    const base = [
        { x: 50, y: 150 },
        { x: 300, y: 170 },
        { x: 450, y: 150 },
        { x: 480, y: 180 },
        { x: 460, y: 215 },
        { x: 440, y: 185 },
        { x: 370, y: 195 },
        { x: 350, y: 225 },
    ];
    
    const base2 = [
        { x: 350, y: 225 },
        { x: 300, y: 235 },
        { x: 290, y: 255 },
        { x: 180, y: 240 },
        { x: 165, y: 220 },
        { x: 155, y: 219 },
        { x: 140, y: 230 },
        { x: 55, y: 220 },
        { x: 50, y: 150 },
    ];
    
    const eye1 = [
        { x: 75, y: 170 },
        { x: 125, y: 175 },
        { x: 135, y: 195 },
        { x: 125, y: 215 },
        { x: 75, y: 208 },
        { x: 70, y: 190 },
        { x: 75, y: 170 },
    ];
    
    const eye2 = [
        { x: 285, y: 215 },
        { x: 278, y: 235 },
        { x: 198, y: 225 },
        { x: 190, y: 205 },
        { x: 200, y: 182 },
        { x: 280, y: 190 },
        { x: 285, y: 215 },
    ];
    
    return (
        <div className='Draw__Question__Mark__Graphic'>
            <EclipseStarGraphicAnimation points={base} />
            <EclipseStarGraphicAnimation points={base2} />
            <EclipseStarGraphicAnimation points={eye1} />
            <EclipseStarGraphicAnimation points={eye2} />
        </div>
    )
}

export default DrawGlasses