import React, { useEffect, useState } from 'react';
import './EclipseHeader.css';

const EclipseHeader = () => {
  const [countdown, setCountdown] = useState(calculateCountdown());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateCountdown() {
    const eclipseDate = new Date('2024-04-08T17:45:00Z');
    const currentDate = new Date();

    if (currentDate > eclipseDate) {
      return {
        ended: true,
        endDateTime: eclipseDate,
      };
    }

    const timeDifference = eclipseDate - currentDate;
    const seconds = Math.floor(timeDifference / 1000);

    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return {
      days,
      hours,
      minutes,
      seconds: remainingSeconds,
      ended: false,
    };
  }

  return (
    <header>
      <div className='eclipseGraphicMainWrapper'>
        <div className='sunGraphicEclipseOverlapSpinner' />
        <div className='sunGraphicEclipseOverlapSpinner2' />
        <div className='sunGraphicEclipse' />
        <div className='sunGraphicEclipseOverlap' />
        <div className='moonGraphicEclipse' />
        <div className='eclipseTextContainer'>
          <h1 className='largeSolarNumber'>2024</h1><h1>Solar Eclipse</h1>
          {countdown.ended ? (
            <p>The eclipse ended on {countdown.endDateTime.toLocaleString()}</p>
          ) : (
            <div className='countdown-container'>
            <div className='countdown-item'>
              <span className='countdown-number'>{countdown.days}</span>
              <span className='countdown-unit'>days</span>
            </div>
            <div className='countdown-item'>
              <span className='countdown-number'>{countdown.hours}</span>
              <span className='countdown-unit'>hours</span>
            </div>
            <div className='countdown-item'>
              <span className='countdown-number'>{countdown.minutes}</span>
              <span className='countdown-unit'>minutes</span>
            </div>
            <div className='countdown-item'>
              <span className='countdown-number'>{countdown.seconds}</span>
              <span className='countdown-unit'>seconds</span>
            </div>
          </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default EclipseHeader;
