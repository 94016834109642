import React from 'react'
import { useState, useEffect } from 'react'
import {primaryAPILink} from '../../../../../config/routes.js'
import _ from 'lodash';

const UserPermissionTableRow = (props) => {
    let tempInfo = props.user;
    const [info, setInfo] = useState(props.user)
    const [active, setActive] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [alerts, setAlerts] = useState(false);
    const [news, setNews] = useState(false);
    const [jobs, setJobs] = useState(false);
    const [cms, setCMS] = useState(false);
    useEffect(()=>{
        if(info.active === 1) {
            setActive(true);
        }
        if(info.isAdmin === 1) {
            setAdmin(true);
        }
        if(info.editAlert === 1) {
            setAlerts(true);
        }
        if(info.editNews === 1) {
            setNews(true);
        }
        if(info.editJobs === 1) {
            setJobs(true);
        }
        if(info.editCMS === 1) {
            setCMS(true);
        }
    }, [])

    //helper function to see if there is any difference before we send an unecessary fetch request to the server
    function checkStaffTextBeforeUpdating(){
        //checks if it is an unchanged list
        if(!_.isEqual(tempInfo, info)) {
            updateStaff()
            tempInfo = info;
        }
    }

    function updateStaff(){

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify({
                fName: info.fName,
                lName: info.lName,
                email: info.email,
                title: info.title,
                category: info.category,
                picture: info.picture,
                username: info.username,
                phone: info.phone,
                active: active,
                isAdmin: admin,
                editNews: news,
                editAlert: alerts,
                editJobs: jobs,
                editCMS: cms,
                staffID: info.staffID
            })
        };
        fetch(`${primaryAPILink}/api/permissions/staffPermissionsUpdate`, requestOptions)
    }

    function removeStaff(){
        if(window.confirm(`Are you sure you wish to remove ${info.fName} ${info.lName} from staff user group?`)) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
                body: JSON.stringify({
                    email: info.email,
                })
            };
            fetch(`${primaryAPILink}/api/permissions/removeStaff`, requestOptions)
            window.location.reload();
        }
    }


    
  return (
    <tr>
        {/* First Name */}
        <td 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            let newInfo = info;
            newInfo.fName = event.target.innerText;
            setInfo(newInfo)
        }}
        onBlur={()=>checkStaffTextBeforeUpdating()}>
            {info.fName}
        </td>

        {/* Last Name */}
        <td 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            let newInfo = info;
            newInfo.lName = event.target.innerText;
            setInfo(newInfo)
        }}
        onBlur={()=>checkStaffTextBeforeUpdating()}>
            {info.lName}
        </td>

        {/* Email */}
        <td 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            let newInfo = info;
            newInfo.email = event.target.innerText;
            setInfo(newInfo)
        }}
        onBlur={()=>checkStaffTextBeforeUpdating()}>
            {info.email}
        </td>

        {/* Phone */}
        <td 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            let newInfo = info;
            newInfo.phone = event.target.innerText;
            setInfo(newInfo)
        }}
        onBlur={()=>checkStaffTextBeforeUpdating()}>
            {info.phone}
        </td>

        {/* Title */}
        <td 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            let newInfo = info;
            newInfo.title = event.target.innerText;
            setInfo(newInfo)
        }}
        onBlur={()=>checkStaffTextBeforeUpdating()}>
            {info.title}
        </td>

        {/* Category */}
        <td>
            <select onInput={(event)=>{
                    let newInfo = info;
                    newInfo.category = event.target.value;
                    setInfo(newInfo)
                    updateStaff()
                }} defaultValue={info.category}>
                <option value="admin" >Admin</option>
                <option value="business">Business</option>
                <option value="info">Info Services</option>
                <option value="research">Research</option>
                <option value="volunteer">Volunteer</option>
            </select>
        </td>

        {/* Picture */}
        <td 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            let newInfo = info;
            newInfo.picture = event.target.innerText;
            setInfo(newInfo)
        }}
        onBlur={()=>checkStaffTextBeforeUpdating()}>
            {info.picture}
        </td>

        {/* Active */}
        <td>
            <input type="checkbox" checked={active} onChange={(event)=>{
                setActive(event.target.checked)
                }}
                onBlur={()=>updateStaff()}></input>
        </td>

        {/* Admin */}
        <td >
            <input type="checkbox" checked={admin} onChange={(event)=>{
                setAdmin(event.target.checked)
                }}
                onBlur={()=>updateStaff()}></input>
        </td>

        {/* Alerts */}
        <td>
            <input type="checkbox" checked={alerts} onChange={(event)=>{
                setAlerts(event.target.checked)
                }}
                onBlur={()=>updateStaff()}></input>
        </td>

        {/* News */}
        <td>
            <input type="checkbox" checked={news} onChange={(event)=>{
                setNews(event.target.checked)
                }}
                onBlur={()=>updateStaff()}></input>
        </td>

        {/* Jobs */}
        <td>
            <input type="checkbox" checked={jobs} onChange={(event)=>{
                setJobs(event.target.checked)
                }}
                onBlur={()=>updateStaff()}></input>
        </td>

        {/* CMS */}
        <td>
            <input type="checkbox" checked={cms} onChange={(event)=>{
                setCMS(event.target.checked)
                }}
                onBlur={()=>updateStaff()}></input>
        </td>

        <td>
            <button className='remove__staff__admin' onClick={()=>{
                removeStaff()
            }}>X</button>
        </td>

    </tr>
  )
}

export default UserPermissionTableRow