import React, { useRef, useEffect, useState } from 'react';
import { loadModules } from 'esri-loader';
import Modal from 'react-modal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import { primaryAPILink } from '../../config/routes';
import './EAssessment.css';
import GenerateDownloadPDF from './GenerateDownloadPDF/GenerateDownloadPDF';
import NewsStoryBold from '../News/NewsStory/NewsStoryComponents/NewsStoryBold/NewsStoryBold'
import NewsStoryParagraph from '../News/NewsStory/NewsStoryComponents/NewsStoryParagraph/NewsStoryParagraph'
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowDown, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root');

const Map = () => {

  document.title = 'IGWS | Env Assessment';

  const [pastAssessments, setPastAssessments] = useState(JSON.parse(localStorage.getItem('pastAssessments')) || []);


  useEffect(() => {
    // Update localStorage when pastAssessments changes
    localStorage.setItem('pastAssessments', JSON.stringify(pastAssessments));
  }, [pastAssessments]);

  const handleAssessmentClick = (assessmentId) => {
    setFormSubmitted(true)
    // Redirect to the assessment with the clicked ID
    const redirectUrl = `${window.location.pathname}?id=${assessmentId}`;
    window.history.pushState({ id: assessmentId }, '', redirectUrl);

    // Fetch and load data for the clicked assessment
    fetchDataForId(assessmentId);
  };


  const mapContainerRef = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPolygon, setCurrentPolygon] = useState([]);
  const [formData, setFormData] = useState({
    organizationName: '',
    contactFirstName: '',
    lastName: '',
    phone: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    customerId: '',
    destinationId: '',
    projectTitle: '',
    projectDescription: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Auto-format the phone number if the input is for the phone field
    const formattedValue = name === 'phone' ? formatPhoneNumber(value) : value;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: formattedValue }));
  };

  const [isFormValid, setFormValid] = useState(false);
  const [isLocationChosen, setLocationChosen] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const initialIsTooltipOpen = localStorage.getItem('isTooltipOpen') !== 'false';

  const [isTooltipOpen, setTooltipOpen] = useState(initialIsTooltipOpen);

  const toggleTooltip = () => {
    const newValue = !isTooltipOpen;
    setTooltipOpen(newValue);

    // Update localStorage with the new value
    localStorage.setItem('isTooltipOpen', newValue);
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const assessmentId = urlParams.get('id');

    if (assessmentId) {
      // If there's an ID in the URL, fetch data for that ID
      setFormSubmitted(true)
      fetchDataForId(assessmentId);
    }
  }, []);

  const fetchDataForId = async (assessmentId) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`https://molly.igs.indiana.edu:8080/api/eassessment/searchbyID?id=${assessmentId}`, requestOptions);
      const result = await response.json();
      setFormSubmitted(true)

      if (result.status !== "failed") {
        // Data fetched successfully, update the UI as needed
        console.log(result);
        setFormData(result.formData)

        setPDFDownload(
          <PDFDownloadLink

            document={<GenerateDownloadPDF img={result.mapImage} results={result.result} formData={result.formData} />}
            fileName="document.pdf"
          >
            {({ blob, url, loading, error }) => (
              <button className='crimson__link__button'>
                {loading ? 'Loading document...' : 'Download PDF'}
              </button>
            )}
          </PDFDownloadLink>
        );
      }
      else {
        handleReload()
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const navigate = useNavigate();

  const handleReload = () => {
    navigate('/eAssessment');
    window.location.reload();
  };

  useEffect(() => {
    if (isModalOpen) {
      loadModules(['esri/Map', 'esri/views/MapView', 'esri/layers/GraphicsLayer', 'esri/widgets/Sketch']).then(
        ([Map, MapView, GraphicsLayer, Sketch]) => {
          const map = new Map({
            basemap: 'streets-vector',
          });

          const view = new MapView({
            container: mapContainerRef.current,
            map,
            center: [-86.3583, 39.8283],
            zoom: 7,
          });

          const graphicsLayer = new GraphicsLayer();
          map.add(graphicsLayer);


          const sketch = new Sketch({
            view,
            layer: graphicsLayer,
            creationMode: 'update',
            creationOptions: { mode: 'polygon' },
            availableCreateTools: ['polygon'],
          });

          view.ui.add(sketch, 'top-right');
          let isDrawingEnabled = true;

          sketch.on('create', (event) => {
            if (isDrawingEnabled && event.state === 'complete' && event.tool === 'polygon') {
              const polygonGraphics = graphicsLayer.graphics.toArray();
              console.log("Polygon Graphics:", polygonGraphics.map((graphic) => graphic.toJSON()));
              const geometry = polygonGraphics[0]?.toJSON()?.geometry;
              console.log(`geometry: ${JSON.stringify(geometry)}`);
              setCurrentPolygon(`geometry: ${JSON.stringify(geometry)}`);
              setModalOpen(false)
              setLocationChosen(true)
            }
          });

          sketch.viewModel.watch('state', (state) => {
            if (state === 'error') {
              console.error(sketch.viewModel.errorMessage);
            }
          });
        }
      );
    }
  }, [isModalOpen]);


   const initialEnvTextOpen = localStorage.getItem('envTextOpen') === 'true';

   const [envTextOpen, setEnvTextOpen] = useState(initialEnvTextOpen);
 
   const toggleEnvText = () => {
     const newValue = !envTextOpen;
     setEnvTextOpen(newValue);
 
     // Update localStorage with the new value
     localStorage.setItem('envTextOpen', newValue);
   };


  const handleApiRequest = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ polygon: currentPolygon, projectInfo: formData, buffer: 0.5, geometryType: 'polygon' }),
    };

    try {
      setFormSubmitted(true);
      const response = await fetch('https://molly.igs.indiana.edu:8080/api/eassessment', requestOptions);
      const result = await response.json();

      if (result.length !== 0) {
        console.log(result);


        const assessmentId = result.id;
        const assessmentTitle = formData.projectTitle; // Assuming projectTitle is a suitable title for the assessment
        const assessmentDate = new Date().toLocaleString(); // Use the current date as the assessment date

        // Update localStorage directly
        const storedAssessments = JSON.parse(localStorage.getItem('pastAssessments'));
        const updatedAssessments = [
          ...storedAssessments,
          { id: assessmentId, title: assessmentTitle, date: assessmentDate },
        ];
        localStorage.setItem('pastAssessments', JSON.stringify(updatedAssessments));

        // Add the new assessment to the state
        setPastAssessments(updatedAssessments);
        // Construct the URL with the ID
        const redirectUrl = `${window.location.pathname}?id=${assessmentId}`;

        // Update the URL with the assessment ID
        window.history.pushState({ id: assessmentId }, '', redirectUrl);


        setPDFDownload(
          <PDFDownloadLink
            document={<GenerateDownloadPDF img={result.mapImage} results={result.result} formData={formData} />}
            fileName="document.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : <button className='crimson__link__button'>Download PDF</button>
            }
          </PDFDownloadLink>
        );
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [pdfDownload, setPDFDownload] = useState(null);

  useEffect(() => {
    // Validate the form fields
    const isValid =
      formData.organizationName &&
      formData.contactFirstName &&
      formData.lastName &&
      isValidPhone(formData.phone) &&
      isValidEmail(formData.email) &&
      formData.projectTitle &&
      formData.projectDescription;

    setFormValid(isValid);
  }, [formData]);

  const isValidEmail = (email) => {
    // Simple email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    // Simple phone number validation using a regular expression
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const formatPhoneNumber = (input) => {
    // Remove any non-numeric characters from the input
    const numericInput = input.replace(/\D/g, '');

    // Remove leading "1" if present
    const trimmedInput = numericInput.startsWith('1') ? numericInput.slice(1) : numericInput;

    // Limit to 10 digits
    const limitedInput = trimmedInput.slice(0, 10);

    // Format the phone number as (XXX) XXX-XXXX
    const formattedPhoneNumber = limitedInput.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    return formattedPhoneNumber;
  };

  const removeAssessment = (assessmentId, event) => {
    // Stop event propagation to prevent handleAssessmentClick from being triggered


    // Remove the assessment with the given ID from both state and localStorage
    const updatedAssessments = pastAssessments.filter((assessment) => assessment.id !== assessmentId);
    setPastAssessments(updatedAssessments);
    localStorage.setItem('pastAssessments', JSON.stringify(updatedAssessments));
    event.stopPropagation();
  };

  return (
    <div id="eAssessmentPage">

      <CrimsonBlockHeader text="Environmental Assessment" />
      <div className='envContentDisplay'>
        <p className='breadcrumbs'><Link to="/services">Services</Link> / <a href="/eAssessment">eAssessment</a></p>

        {/* Conditionally render Past Assessments section */}
        {pastAssessments.length > 0 && (
          <div>
            <NewsStoryBold text="Past Assessments"></NewsStoryBold>
            <ul className='pastAssessmentsList'>
              {pastAssessments.map((assessment) => (
                <li key={assessment.id} onClick={() => handleAssessmentClick(assessment.id)}>
                  <span className='assessmentTitle'>{assessment.title}</span>
                  <span className='assessmentDate'>
                    {assessment.date.split(',').join('\n')}
                  </span>
                  <button onClick={() => removeAssessment(assessment.id)} className='xButtonAssessmentPast'><FontAwesomeIcon icon={faXmark} /></button>
                </li>
              ))}
            </ul>
          </div>
        )}


        {isFormSubmitted ? (
          <div>
            {/* Render completed assessment window */}
            <NewsStoryBold text={pdfDownload ? `Assessment Completed : ${formData.projectTitle}` : "Processing Assessment"}></NewsStoryBold>
            {pdfDownload ? pdfDownload : <p>loading...</p>}
            <button onClick={handleReload} className='crimson__link__button'>Submit a new Assessment</button>
          </div>
        ) : (
          <>

            <div className="colapsableWhatIsEnvAssess" onClick={toggleEnvText}><NewsStoryBold text="What is the environment assessment?" /> {envTextOpen ? (<FontAwesomeIcon icon={faEye} className='news__story__bold__text'/>) : (<FontAwesomeIcon icon={faEyeSlash} className='news__story__bold__text'/>)}</div>
            {envTextOpen ? (
              <><NewsStoryParagraph text="The IGWS Environmental Assessment is a sophisticated geospatial tool designed to survey user-specified locations using a series of geospatial APIs. Focused on evaluating geologic hazards, mineral resources, and active or abandoned mineral extraction sites, the platform provides critical insights for risk assessment and resource management." />
                <NewsStoryParagraph text=" Utilizing Geographic Information System (GIS) technology, the assessment generates comprehensive reports, visually representing survey findings and promoting transparency. By seamlessly integrating real-time data through APIs, the IGWS Environmental Assessment facilitates informed decision-making for sustainable land use planning and hazard mitigation in Indiana." />
              </>
            ) : null}



            <form className='EAssessment-form'>
              <div className='formBoxEassessment'>
                <h2>Organization Information</h2>
                <div>

                  <label htmlFor="organizationName">Organization Name*</label>
                  <input
                    type="text"
                    id='organizationName'
                    name="organizationName"
                    value={formData.organizationName}
                    onChange={handleInputChange}
                    maxLength={75}
                    required
                  />
                </div>
                <div className='form-left-right'>
                  <div>
                    <label htmlFor='contactFirstName'>First Name*</label>
                    <input
                      type="text"
                      id="contactFirstName"
                      name="contactFirstName"
                      value={formData.contactFirstName}
                      onChange={handleInputChange}
                      maxLength={75}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor='lastName'>Last Name*</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      maxLength={75}
                      required
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor='phone'>Phone*</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor='email'>Email*</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    maxLength={75}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="addressLine1">Address Line 1</label>
                  <input
                    type="text"
                    name="addressLine1"
                    id="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleInputChange}
                    maxLength={75}

                  />
                </div>
                <div>
                  <label htmlFor="addressLine2">Address Line 2</label>
                  <input
                    type="text"
                    id="addressLine2"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleInputChange}
                    maxLength={75}
                  />
                </div>
                <div>
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    maxLength={58}

                  />
                </div>
                <div>
                  <label htmlFor="state">State</label>
                  <select
                    name="state"
                    id="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div>
                  <label htmlFor='zip'>Zip</label>
                  <input
                    type="text"
                    name="zip"
                    id="zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                    maxLength={15}

                  />
                </div>
              </div>
              <div className='formBoxEassessment'>
                <h2>Project Information</h2>
                <div>
                  <label htmlFor="customerId">Customer Project ID</label>
                  <input
                    type="text"
                    name="customerId"
                    id="customerId"
                    value={formData.customerId}
                    onChange={handleInputChange}
                    maxLength={75}

                  />
                </div>
                <div>
                  <label htmlFor="destinationId">Destination ID</label>
                  <input
                    type="text"
                    name="destinationId"
                    id="destinationId"
                    value={formData.destinationId}
                    onChange={handleInputChange}
                    maxLength={75}

                  />
                </div>
                <div>
                  <label htmlFor="projectTitle">Project Title*</label>
                  <input
                    type="text"
                    name="projectTitle"
                    id="projectTitle"
                    value={formData.projectTitle}
                    onChange={handleInputChange}
                    maxLength={75}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="projectDescription">Project Description*</label>
                  <textarea
                    name="projectDescription"
                    id="projectDescription"
                    value={formData.projectDescription}
                    onChange={handleInputChange}
                    maxLength={200}
                    rows={6}
                    style={{ minHeight: '100px' }} // Set resize to 'none'
                    required
                  />
                </div>
                <div>
                  <p>*required</p>
                </div>
                <div className='envAssessmentButtonWrapper'>
                  <button type="button" onClick={() => setModalOpen(true)} className={isLocationChosen ? 'chooseLocationEnvAssessment' : 'chooseLocationEnvAssessment false'}>{isLocationChosen ? "Location Chosen" : "Choose Location"}</button>
                  <button className="submitEnvAssessment" type="button" onClick={handleApiRequest} disabled={!isFormValid || !isLocationChosen}>Generate Assessment</button>
                </div>
              </div>
            </form>




            <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} style={{
              content: {
                zIndex: 1001, // Adjust the zIndex as needed
                width: '80vw',
                height: '80vh',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                transform: 'translate(-50%, -50%)',
              },
              overlay: {
                zIndex: 1000, // Adjust the zIndex as needed
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change the background color and opacity as needed
              },
            }}>
              {/* Tooltip */}
              {isTooltipOpen ? (
                <>
                  <div className="envtooltip">
                    <p>Tutorial</p>
                    <ol>
                      <li>Zoom to the location of the project site on the map by using the zoom tool.</li>
                      <li>Click the polygon icon ( <span className='polygonIcon'></span> ) in the top right to start drawing the shape of your location.</li>
                      <li>Click down on the map to drop a point. Doubleclick to complete the polygon.</li>
                      <li>A default buffer size of 0.5 miles will be applied to your project area.</li>
                    </ol>
                    <button onClick={() => toggleTooltip()}>Got it!</button>
                  </div>
                </>
              ) : (<button className="envtooltipHelp" onClick={()=> toggleTooltip()}>Tutorial</button>)}
              <div ref={mapContainerRef} style={{ height: '100%' }}></div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default Map;