import React from 'react'
import './NasaViewEclipse.css'

const NasaViewEclipse = () => {
    return (
        <figure className='nasaViewEclipse'>
            <iframe src="https://eyes.nasa.gov/apps/solar-system/#/story/total_solar_eclipse" title="Total Solar Eclipse" className="smd-iframe-iframe margin-left-auto margin-right-auto border-0" allow="fullscreen">
            Could Load Element
        </iframe>
   
        </figure>
    )
}

export default NasaViewEclipse