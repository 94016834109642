import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import UserPermissionAddStaff from './UserPermissionsTable/UserPermissionAddStaff/UserPermissionAddStaff'
import UserPermissionsTable from './UserPermissionsTable/UserPermissionsTable'

const PublicAlerts = () => {
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="perms"/>
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="User Permissions" />
            <AdminContentExplain text={["User permissions is used to add staff members to the /staff page and assign what permissions they have on the website", "Make sure email aligns with their current IU email", "Profile pictures should be uploaded to resourcespace and linked in the picture tab", "Users will only be able to login/ displayed on the site after they have been checked 'active'"]}></AdminContentExplain>
            <UserPermissionsTable />
            <UserPermissionAddStaff />
        </div>
    </div>
  )
}

export default PublicAlerts