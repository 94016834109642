import React, { useEffect, useState, useRef } from 'react';
import './DiscoverEclipse.css';
import { useInView } from 'react-intersection-observer';

const DiscoverEclipse = () => {

    const myGraphicRef = useRef(null);

    function generateEclipseGraphic() {
        const totalEclipsePics = 14;
        const myPicStages = 100 / totalEclipsePics;
        const scalingFactor = 1.5; // Adjust this factor for desired scaling
        let eclipseGraphics = [];

        for (let i = totalEclipsePics; i >= 0; i--) {
            let rawTopDistance = Math.pow(totalEclipsePics / 2 - i, 2) * myPicStages;
            let scaledTopDistance = rawTopDistance * scalingFactor;
            let topDistance = `${scaledTopDistance/ 7.0        }%`

            let moonShadow = "0 0 0 0"
            let zIndex = 1
            let overflow = "hidden"
            if(i == 7) {
                moonShadow = `0px 0px 10px 5px rgb(255, 255, 255)`
                zIndex = 2
                overflow = "visible"

            }
                

            let myMoonCSS = {
                backgroundColor: 'black',
                height: '50px',
                width: '50px',
                borderRadius: '50%',
                top: `${myPicStages * i - 50}%`,
                left: `${myPicStages * i - 50}%`,
                position: 'absolute',
                boxShadow: moonShadow,
                zIndex: zIndex

                
            }

            let mySunCSS = {
                background:
                    'radial-gradient(ellipse at 50% 50%, #e9e69f 0%, #f1ed76 51.74%, #f3c143 72.22%, #f5c44a 94%, #d27837 95%, #cc702e 100%) 0px 0px / 50px 50px no-repeat no-repeat',
                height: '50px',
                width: '50px',
                borderRadius: '50%',
            }



            eclipseGraphics.push(
                <div key={i}
                    className='smallEclipseGraphicSubSet'
                    style={{  position: 'relative', width: '50px', height: '50px', top: topDistance, margin: `0 3px`, animation: `fadeMoonIn 1s ${i * 0.1}s forwards`, opacity: 0, overflow:overflow, borderRadius: '50%'}}>
                        
                    <div className='miniSun' style={mySunCSS}></div>
                    <div className='miniMoon' style={myMoonCSS}></div>
                    
                </div>);
        }

        return eclipseGraphics;
    }

    const [myGraphic, setMyGraphic] = useState(<></>)

    useEffect(() => {
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScrollIntoView);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('scroll', handleScrollIntoView);
        };
      }, []); // Empty dependency array ensures that the effect runs only once
    

    const handleScrollIntoView = () => {
        // Get the position of the element relative to the viewport
        const elementPosition = myGraphicRef.current.getBoundingClientRect();
    
        // Check if the element is in the viewport
        if (elementPosition.top >= 0 && elementPosition.bottom <= window.innerHeight) {
          setMyGraphic(generateEclipseGraphic())
        }
      };

    return (
        <section className='discover__the__eclipse' ref={myGraphicRef}>
        <div className='eclipseSmallGraphicWrapper'>
            {myGraphic}
        </div>
            <div className='discover__the__eclipse__text'>
            <h2>Discover the Eclipse</h2>
            <p>What is a Solar Eclipse? Unravel the science behind this celestial phenomenon. Learn how the moon perfectly aligns itself between the Earth and the Sun, creating the spectacular show that captures the world's attention.</p>
            </div>
        </section>
    );
}

export default DiscoverEclipse;
